<template>
  <div class="home">
    <div class="header">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          left: 200px;
        "
      >
        <p class="title">首页>>创建微课程</p>
      </div>
      <div class="right">
        教师:{{ user.nickName }}
        <el-button type="primary" class="logbt" @click="userLogout"
          >退出登录</el-button
        >
      </div>
    </div>
    <div>
      <div style="width: 50%; margin: 0 auto; margin-top: 20px">
        <el-button @click="openCreateLive">新建直播间</el-button>
        <h2>当前创建直播间列表</h2>
        <CardLive
          v-for="(item, index) in liveList"
          @delete="userDeleteLive(item)"
          @edit="editLiveUser(item)"
          :password="item.channelPasswd"
          @startStream="startStream(item)"
          @handlePassword="handleSetpassword(item)"
          :key="index"
          :start-time="item.startTime"
          :coverUrl="item.imgUrl"
          :endTime="item.endTime"
          :lectureName="item.lecturerName"
          :startTime="item.startTime"
          :title="item.roomName"
          :status="item.status"
          :userPassword="item.password"
        ></CardLive>
      </div>

      <el-dialog title="新建直播间" :visible.sync="dialogVisible" width="30%">
        <h1 class="kctitle">创建微课程</h1>

        <el-form ref="form" :model="form" label-width="80px" class="form">
          <el-form-item label="房间名称">
            <el-input v-model="form.roomName"></el-input>
          </el-form-item>

          <el-form-item label="讲师姓名">
            <el-input v-model="form.lecturerName"></el-input>
          </el-form-item>
          <el-form-item label="直播场景">
            <el-radio-group v-model="form.newScene"  @change="getTemplateList" >
              <el-radio-button :key="index" v-for="(item,index) in screenList" :label="item.value">{{ item.sceneName }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="课程模板">
            <el-select v-model="form.template" placeholder="请选择课程模板">
              <el-option
                v-for="item in templateList"
                :key="item.templateValue"
                :label="item.template"
                :value="item.templateValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="直播日期">
            <el-date-picker
              v-model="form.liveDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="活动时间">
            <el-col :span="11">
              <el-time-picker
                type="time"
                placeholder="开始时间"
                v-model="form.startTime"
                value-format="HH:mm:ss"
                style="width: 100%"
              ></el-time-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-time-picker
                type="time"
                placeholder="选择时间"
                value-format="HH:mm:ss"
                v-model="form.endTime"
                style="width: 100%"
              ></el-time-picker>
            </el-col>
          </el-form-item>

          <el-form-item label="直播封面">
            <!-- Make sure to use your ImageUpload component here -->
            <ImageUpload v-model="form.imgUrl" :limit="1"></ImageUpload>
            <!-- <el-input v-model="form.coverImage" placeholder="请输入直播封面"></el-input> -->
          </el-form-item>
          <el-form-item label="密码">
            <!-- Make sure to use your ImageUpload component here -->
            <el-input
              v-model="form.channelPasswd"
              placeholder="直播密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog
        title="设置密码"
        width="200px"
        :visible.sync="passwordDialogVisible"
      >
        <p>请输入进入直播间密码</p>
        <el-input
          type="password"
          :show-password="true"
          v-model="livePasswordInfo.password"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitPassword">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  addLive,
  getLive,
  editInfo,
  deleteLive,
  setPassword,
  userInfo,
  logout,
  getScreen,
  getYliveTemplate
} from "@/api/api";
// import CardLiveVue from './components.vue/CardLive.vue';
import CardLive from "./components.vue/CardLive.vue";
import moment from "moment";
export default {
  name: "HomeView",
  components: { CardLive },
  data() {
    return {
      form: {}, // Initialize as an object
      dialogVisible: false,
      liveList: [],
      passwordDialogVisible: false,
      livePasswordInfo: {
        channelId: null,
        password: "",
      },
      user: {},
      screenList: [],
      templateList:[]
    };
  },
  created() {
    this.Live();
    this.getUserInfo();
    this.getScreenList();
  },
  methods: {
    getTemplateList(e){
      console.log(e);
      getYliveTemplate({value:e}).then((res) => {
        console.log(res);
        this.templateList = res.data;
        this.form.template = res.data[0].templateValue
      });
    },
    getScreenList() {
      getScreen().then((res) => {
        console.log(res);
        this.screenList = res.data;
      });
    },
    getUserInfo() {
      userInfo().then((res) => {
        // console.log(res);
        this.user = res.data.user;
      });
    },
    userLogout() {
      logout().then((res) => {
        if (res.code == 200) {
          this.$router.replace({ path: "/" });
        }
      });
    },
    submitPassword() {
    
      setPassword(this.livePasswordInfo).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.passwordDialogVisible = false;
        }
      });
    },
    handleSetpassword(e) {
      // setPassword({
      //   channelId:e.channelId
      this.livePasswordInfo.channelId = e.channelId;
      // this.livePasswordInfo.password=e.password
      this.passwordDialogVisible = true;

      // })
    },
    userDeleteLive(e) {
      this.$modal
        .confirm("确定删除该直播吗？")
        .then(function () {
          return deleteLive({ channelId: e.channelId });
        })
        .then(() => {
          this.Live();

          this.$modal.msgSuccess("删除成功");
        })
        .catch(() => {});
    },
    editLiveUser(e) {
      editInfo(e.channelId);
    },
    startStream(item) {
      // location.href = item.url;
      window.open(item.url);
    },
    Live() {
      getLive().then((res) => {
        console.log(res);
        this.liveList = res.data;
      });
    },
    openCreateLive() {
      this.dialogVisible = true;
    },
    onSubmit() {
      if(!this.form.newScene){
        this.$message.error("请选择直播场景");
        return;
      }
      if(!this.form.template){
        this.$message.error("请选择直播模板");
        return;
      }
      console.log(`${this.form.liveDate} ${this.form.endTime}`);

      this.form.startTime = `${this.form.liveDate} ${this.form.startTime}`;
      this.form.endTime = `${this.form.liveDate} ${this.form.endTime}`;
      addLive(this.form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "创建成功",
            type: "success",
          });
          this.Live();
          this.dialogVisible = false;
          // this.$router.push({ path: "/live",query:{
          //   pushUrl:res.pushUrl
          // } });
        } else {
          this.$message({
            message: "创建失败",
            type: "error",
          });
        }
        this.form.startTime = moment(new Date()).format("HH:mm:ss");
        this.form.endTime = moment(new Date()).format("HH:mm:ss");
      });
    },
  },
};
</script>

<style>
.right {
  color: #fff;
  position: relative;
  left: 70%;
  width: 220px;
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.right-menu {
  width: 270px;
  background: #333333;
}
.form-box {
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 61px; /* adjust as needed */
}

.kctitle {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  font-size: 38px;
  color: #306060;
  line-height: 59px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.form {
  width: 764rpx;
  display: flex;
  flex-direction: column;
}

.title {
  color: #fff;
}

.header {
  width: 100%;
  height: 80px;
  background: #306060;
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 20px; /* adjust as needed */
}
</style>
