// 引入 Axios
import axios from "axios";
export const baseURL = "http://zhibo.yuanzhisuyang.top/prod-api/";
// export const baseURL = "http://127.0.0.1:8080";

import { getToken } from "@/utils/auth";
import cache from '@/plugins/cache'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import router from "@/router";
import route from "vue-router"
// 创建 Axios 实例
const instance = axios.create({
  baseURL: baseURL, // 设置基础URL
  timeout: 5000, // 设置超时时间
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // 可以在此处添加一些通用的请求头或处理逻辑
    const isToken = (config.headers || {}).isToken === false;
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false

    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // // get请求映射params参数
    // if (config.method === "get" && config.params) {
    //   let url = config.url + "?" + tansParams(config.params);
    //   url = url.slice(0, -1);
    //   config.params = {};
    //   config.url = url;
    // }
    // if (
    //   !isRepeatSubmit &&
    //   (config.method === "post" || config.method === "put")
    // ) {
    //   const requestObj = {
    //     url: config.url,
    //     data:
    //       typeof config.data === "object"
    //         ? JSON.stringify(config.data)
    //         : config.data,
    //     time: new Date().getTime(),
    //   };
    //   const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    //   const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
    //   if (requestSize >= limitSize) {
    //     console.warn(
    //       `[${config.url}]: ` +
    //         "请求数据大小超出允许的5M限制，无法进行防重复提交验证。"
    //     );
    //     return config;
    //   }
    //   const sessionObj = cache.session.getJSON("sessionObj");
    //   if (
    //     sessionObj === undefined ||
    //     sessionObj === null ||
    //     sessionObj === ""
    //   ) {
    //     cache.session.setJSON("sessionObj", requestObj);
    //   } else {
    //     const s_url = sessionObj.url; // 请求地址
    //     const s_data = sessionObj.data; // 请求数据
    //     const s_time = sessionObj.time; // 请求时间
    //     const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
    //     if (
    //       s_data === requestObj.data &&
    //       requestObj.time - s_time < interval &&
    //       s_url === requestObj.url
    //     ) {
    //       const message = "数据正在处理，请勿重复提交";
    //       console.warn(`[${s_url}]: ` + message);
    //       return Promise.reject(new Error(message));
    //     } else {
    //       cache.session.setJSON("sessionObj", requestObj);
    //     }
    //   }
    // }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    const curRutes = window.location.href.split("#")
    console.log(curRutes[curRutes.length - 1])
  
    if (response.data.code == 401) {
      if(curRutes[curRutes.length - 1] != "/")
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
        }).then(res=>{
           location.href = '/login'
        })
    }
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 封装 GET 请求
export const get = (url, params) => {
  return instance.get(url, { params });
};

// 封装 POST 请求
export const post = (url, data) => {
  return instance.post(url, data);
};

// 导出 Axios 实例
export default instance;
